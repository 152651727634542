.container {
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
  text-align: center;

  h2 {
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    input {
      padding: 10px;
      border-radius: 4px;
      font-size: 16px;
      outline: none;
      border: none;
      box-shadow: 0px 4px 4px 0px #00000040 inset;

    }

    button {
      font-size: 16px;
      border-radius: 4px;
      padding: 10px 20px;
      color: white;
      transition: background-color 0.3s, color 0.3s;
      cursor: pointer;
      background-color: black;
      border: none;
      &:hover {
        opacity: 0.9;
      }
    }
  }

  .toggle-theme {
    margin-top: 20px;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
  }
}