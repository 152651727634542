.details_and_payment {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &_config {
    display: flex;
    justify-content: space-between;
    @media(max-width:500px){
      flex-direction: column;
      gap: 40px;
    }
    &_delivery {
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: start;

      p {
        font-family: Chivo;
        font-size: 15px;
        font-weight: 400;
        line-height: 12px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      &_box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        label {
          font-family: Chivo;
          font-size: 13px;
          font-weight: 300;
          line-height: 12px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          cursor: pointer;
        }

        input[type="radio"] {
          accent-color: black;
          cursor: pointer;

        }
      }
    }

    &_payment {
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: start;

      p {
        font-family: Chivo;
        font-size: 15px;
        font-weight: 400;
        line-height: 12px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      &_box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        label {
          font-family: Chivo;
          font-size: 13px;
          font-weight: 300;
          line-height: 12px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          cursor: pointer;
        }

        input[type="radio"] {
          accent-color: black;
          cursor: pointer;

        }
      }
    }
  }

  &_note {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;

    p {
      font-family: Chivo;
      font-size: 15px;
      font-weight: 300;
      line-height: 12px;
      letter-spacing: 0.12em;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

    }

    textarea {
      width: 100%;
      height: 150px;
      resize: none;
      border: none;
      background-color: #F8F9FA;
      box-shadow: 0px 4px 4px 0px #00000040 inset;
      padding: 15px;
      font-family: Chivo;
      font-size: 15px;
      font-weight: 200;
      line-height: 17.85px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

    }

  }

  &_info {
    display: flex;
    justify-content: space-between;
    gap: 50px;

    p {
      width: 320px;
      font-family: Chivo;
      font-size: 11px;
      font-weight: 300;
      line-height: 12px;
      letter-spacing: 0.12em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      @media (max-width:550px){
        width: 100%;
      }
    }

    a {
      font-family: Chivo;
      font-size: 12px;
      font-weight: 300;
      line-height: 12px;
      text-align: center;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: black;
      cursor: pointer;
    }
  }

  
}