.order {
  width: 100%;
  display: flex;
  padding: 150px 0;

  @media (max-height:800px) {
    padding: 100px 0;
  }

  @media(max-width:1200px) {
    height: unset;
    padding: 30px;
  }

  @media(max-width:700px) {
    height: unset;
    padding: 60px;
  }

  &_container {
    display: flex;
    gap: 150px;
    margin: 0 auto;

    @media(max-width:1200px) {
      flex-direction: column-reverse;
      gap: 50px;
    }

    &_left {
      display: flex;
      flex-direction: column;
      gap: 25px;
      align-items: start;
      position: relative;

      &_title {
        font-family: "Chivo", sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0.12em;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: black;
      }

      &_tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;

        @media(max-width:500px) {
          display: none;
        }

        div {
          hr {
            width: 45px;
          }

          p {
            font-family: "Chivo", sans-serif;
            font-size: 12px;
            font-weight: 200;
            line-height: 12px;
            letter-spacing: 0.12em;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;

          }

          .activ {
            font-weight: 400;
            border-color: black;
          }
        }
      }

      &_info {
        display: flex;
        gap: 8px;
        align-items: center;

        div {
          p {
            font-family: Chivo;
            font-size: 10px;
            font-weight: 400;
            line-height: 11.9px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #6A6A6A;
          }
        }
      }

      &_content {
        width: 100%;
        height: 100%;
      }

      &_button {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 10px;
        small{
          font-size: 12px;
          margin-left: 5px;
        }
        button {
          border-radius: 24px;
          border: none;
          background-color: black;
          color: white;
          width: 150px;
          padding: 10px 0;
          cursor: pointer;

          @media(max-width:1200px) {
            margin-bottom: 20px;
          }
        }
      }
    }

    &_right {
      border: 1px solid #6A6A6A;
      min-width: 350px;
      max-height: fit-content;

      @media(max-width:550px) {
        min-width: unset;
        width: 100%;
      }

      &_title {
        padding: 13px;
        border-bottom: 1px solid #6A6A6A;

        p {
          font-family: Chivo;
          font-size: 13px;
          font-weight: 400;
          line-height: 15.47px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

        }
      }

      &_order {
        &_item {
          padding: 13px 20px;
          display: flex;
          gap: 20px;
          border-bottom: 1px solid #6A6A6A;
        }

        &_info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: start;
          padding: 5px 0;

          p {
            font-family: Chivo;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.28px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;

          }
        }
      }

      &_delivery {
        padding: 12px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #6A6A6A;

        p {
          font-family: Chivo;
          font-size: 13px;
          font-weight: 200;
          line-height: 15.47px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #6A6A6A;
        }
      }

      &_total {
        padding: 12px;
        display: flex;
        justify-content: space-between;

        p {
          font-family: Chivo;
          font-size: 13px;
          font-weight: 400;
          line-height: 15.47px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

        }
      }
    }
  }

  .delivery_price_modal {
    position: absolute;
    width: 90%;
    height: 90%;
    background-color: rgba(255, 255, 255, 0.892);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 20px;
    overflow: scroll;
  }
}