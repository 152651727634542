.profile {
  display: flex;
  padding: 40px 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media(max-width:450px) {
    padding: 40px 20px 60px 20px;
  }

  &_container {
    width: 1128px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    &_title {
      font-size: 30px;
      font-weight: 300;
      line-height: 36.31px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    @media (max-width:1231px) {
      width: 1000px;

    }

    @media (max-width:1100px) {
      width: 100%;
    }

    &_block {
      display: flex;
      flex-direction: column;
      gap: 40px;

      &_sidebar {
        display: flex;
        justify-content: space-between;

        @media (max-width:1100px) {
          flex-direction: column;
          gap: 10px;
        }

        &_left {
          display: flex;
          gap: 30px;

          @media (max-width:1100px) {
            flex-wrap: wrap;
            gap: 10px;
          }

          .active {
            background-color: #000000;
            color: white;

            svg {
              path {
                stroke: white !important;
              }
            }
          }

          &_edit {
            width: 180px;
            height: 30px;
            border: 1px solid #D9D9D9;
            display: flex;
            align-items: center;
            padding: 10px 13px;
            gap: 11px;
            cursor: pointer;

            &:hover {
              border: 1px solid #000000;
            }

            img {
              width: 12px;
              height: 12px;
            }

            p {
              font-size: 12px;
              font-weight: 300;
              line-height: 14.52px;
              text-align: center;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;

            }
          }

          >div {
            width: 180px;
            height: 30px;
            border: 1px solid #D9D9D9;
            display: flex;
            align-items: center;
            padding: 10px 13px;
            gap: 11px;
            cursor: pointer;

            &:hover {
              border: 1px solid #000000;
            }

            img {
              width: 12px;
              height: 12px;
            }

            p {
              font-size: 12px;
              font-weight: 300;
              line-height: 14.52px;
              text-align: center;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
            }
          }
        }
      }
    }

    &_info {
      &_img {
        display: flex;
        gap: 24px;
        align-items: center;

        &_cirkule {
          padding: 22px;
          border: 1px solid black;
          border-radius: 50%;

          p {
            font-size: 35px;
            font-weight: 300;
            line-height: 42.36px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;

          }
        }

        &_fullName {
          text-align: left;

          h4 {
            font-size: 18px;
            font-weight: 500;
            line-height: 21.78px;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;

          }

          p {
            font-size: 12px;
            font-weight: 300;
            line-height: 14.52px;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            margin-top: 11px;
          }
        }
      }
    }


  }

  // &_sidebar {
  //   display: flex;
  //   justify-content: space-between;
  // }

  // &_content {
  //   padding: 20px;

  //   .account-form {

  //     &_save {
  //       border-bottom: 1px solid #ddd;
  //       padding-bottom: 30px;
  //       display: flex;
  //       justify-content: space-between;
  //       align-items: flex-start;
  //       position: relative;

  //       button {
  //         position: absolute;
  //         right: 0;
  //         bottom: 0;
  //         margin-bottom: 11px;
  //         border: none;
  //         color: #4A4A4A;
  //         background-color: #F5F5F5;
  //         box-shadow: 0px 1px 3px 1px #00000026;
  //         box-shadow: 0px 1px 2px 0px #0000004D;
  //         padding: 5px 24px;
  //         border-radius: 14px;
  //         opacity: 80%;
  //         font-size: 12px;
  //         font-weight: 200;
  //         line-height: 14.28px;
  //         text-align: center;
  //         text-underline-position: from-font;
  //         text-decoration-skip-ink: none;
  //         transition: .3s all;
  //         cursor: pointer;

  //         &:hover {
  //           background-color: #000;
  //           color: white;
  //         }
  //       }

  //       h2 {
  //         margin-bottom: 20px;
  //       }
  //     }

  //     section {
  //       margin-bottom: 20px;

  //       h3 {
  //         margin-bottom: 10px;
  //         padding-bottom: 5px;
  //       }

  //       .form-group {
  //         display: flex;
  //         flex-wrap: wrap;
  //         gap: 10px;
  //         margin-bottom: 15px;

  //         input {
  //           padding: 10px;
  //           border: 1px solid #ccc;
  //           border-radius: 5px;
  //           font-size: 14px;

  //           &:focus {
  //             border-color: #007bff;
  //             outline: none;
  //           }
  //         }

  //         .error {
  //           flex-basis: 100%;
  //           color: red;
  //           font-size: 12px;
  //         }

  //         &.gender {
  //           flex-direction: row;

  //           label {
  //             display: flex;
  //             align-items: center;
  //             gap: 5px;
  //             font-size: 14px;

  //             input {
  //               margin: 0;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

.active {
  background-color: #000000;
  color: white;

  svg {
    path {
      stroke: white !important;
    }
  }
}

.logOut {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  p {
    font-size: 12px;
  }
}