.category {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &_control {
    display: flex;
    flex-direction: column;
    gap: 10px;

    form {
      display: flex;
      gap: 10px;
      align-items: center;

      input[type="text"] {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;
        flex-grow: 1;
      }

      input[type="file"] {
        padding: 5px;
        font-size: 14px;
      }

      button {
        padding: 10px 20px;
        background-color: #000000;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #323232;
        }
      }
    }
  }

  &_table {
    table {
      width: 100%;
      border-collapse: collapse;
      background-color: #fff;
      border-radius: 8px;
      overflow: hidden;

      thead {
        background-color: #000000;
        color: #fff;

        th {
          padding: 10px;
          text-align: center;
          font-size: 14px;
        }
      }

      tbody {
        tr {
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #f1f1f1;
          }

          td {
            padding: 10px;
            border-bottom: 1px solid #eee;

            img {
              border-radius: 4px;
            }

            &:last-child {
              gap: 10px;
              justify-content: center;
              align-items: center;
              height: 100%;

              img {
                cursor: pointer;
                transition: transform 0.2s ease;
                margin-left: 10px;

                &:hover {
                  transform: scale(1.2);
                }
              }
            }
          }
        }

        tr:last-child td {
          border-bottom: none;
        }
      }
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    &_content {
      background: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
      width: 400px;
      text-align: center;

      h3 {
        margin-bottom: 20px;
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 10px;

        input {
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 14px;
        }

        button {
          padding: 10px;
          border: none;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;

          &:first-of-type {
            background-color: #000000;
            color: #fff;

            &:hover {
              background-color: #333333;
            }
          }

          &:last-of-type {
            background-color: #f44336;
            color: #fff;

            &:hover {
              background-color: #c62828;
            }
          }
        }
      }
    }
  }

}