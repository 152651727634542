.catalog {
  display: flex;
  padding: 40px 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media(max-width:450px) {
    padding: 40px 60px 60px 60px;
  }
  &_container {
    width: 1280px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    
    @media (max-width:1200px) {
      width: 1000px;
    }

    @media (max-width:1000px) {
      width: 600px;
    }

    @media (max-width:750px) {
      width: 100%;
      justify-content: center;
    }

    &_title {
      font-size: 30px;
      font-weight: 300;
      line-height: 36.31px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    &_category {
      position: relative;

      &_list {
        display: flex;
        position: relative;

        @media (max-width:1800px) {
          justify-content: space-evenly;

        }

        @media (max-width:750px) {
          flex-direction: column;

          ul {
            text-align: center !important;
            margin: 0 auto;
            padding: 0;

            li {
              text-align: center !important;
            }
          }
        }

        &_item {
          width: 50%;
          flex-wrap: wrap;
          gap: 20px;
          animation: wave 2s ease-in-out infinite;
          margin-top: 30px;
          display: none;

          @media (max-width:1800px) {
            display: flex;
          }

          @media (max-width:1000px) {
            width: 58%;
          }

          @media (max-width:750px) {
            width: 100%;
          }

          .swiper {

            .swiper-wrapper {

              div {
                &:nth-child(2n) {
                  transition: 3.5s all;
                  transform: translateY(80px)
                }

                @media (max-width:750px) {
                  &:nth-child(2n) {
                    transition: 3.5s all;
                    transform: translateY(0px)
                  }
                }
              }

            }
          }

          @keyframes wave {
            0% {
              transform: translateY(0);
            }

            50% {
              transform: translateY(-10px);
            }

            100% {
              transform: translateY(0);
            }
          }
        }

        ul {
          text-decoration: none;
          list-style: none;
          text-align: left;
          padding: 0;
          .activ {
            color: #000000;
          }

          li {
            margin-bottom: 20px;
            font-size: 25px;
            font-weight: 300;
            line-height: 30.26px;
            letter-spacing: 0.15em;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            max-width: 376px;
            cursor: pointer;
            color: #6A6A6A;

            &:hover {
              color: #000000;

            }
          }
        }
      }

      .size {
        p {
          font-size: 30px;
          font-weight: 100;
          line-height: 40.51px;
          letter-spacing: 0.15em;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          max-width: 450px;

        }
      }

      &_name {
        position: absolute;
        right: 200px;
        bottom: -170px;

        @media (max-width:750px) {
          position: unset;

        }

        p {
          font-size: 100px;
          font-weight: 100;
          line-height: 121.02px;
          letter-spacing: 0.25em;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #6A6A6A;
          max-width: 568px;
        }

        @media (max-width:1200px) {
          right: 150px;
          bottom: -100px;

          p {
            font-size: 50px;
            max-width: 308px;
            line-height: 70px;
            text-wrap: wrap;
          }
        }

        @media (max-width:750px) {
          display: flex;
          justify-content: center;
          padding-top: 20px;

          p {
            max-width: 100%;
          }
        }
      }

      &_slider {
        width: 100%;
        display: flex;
        gap: 40px;
        position: relative;

        @media(max-width:1800px) {
          display: none;
        }

        @media (max-width:1450px) {
          display: none;
        }

        &_track {
          display: flex;

          &_item {
            animation: wave 2s ease-in-out infinite;

            @keyframes wave {
              0% {
                transform: translateY(0);
              }

              50% {
                transform: translateY(-10px);
              }

              100% {
                transform: translateY(0);
              }
            }
          }
        }
      }
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  transition: 3.5s all;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}