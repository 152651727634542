.product_item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 55px;

  &_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 80px;

    &_title {
      p {
        font-family: Inter;
        font-size: 20px;
        font-weight: 400;
        line-height: 24.2px;
        letter-spacing: 0.14em;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

      }
    }

    &_box {
      display: flex;
      gap: 50px;

      @media (max-width:1200px) {
        flex-direction: column;
      }

      &_img {
        .product-page {
          display: flex;
          align-items: flex-start;
          gap: 24px;

          @media (max-width:900px) {
            flex-direction: column;

          }

          &__main-image {
            img {
              width: 370px;
              height: 390px;
              object-fit: cover;

              @media (max-width:500px) {
                width: 270px;
                height: 290px;

              }
            }
          }

          &__thumbnails {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 16px;

            @media (max-width:900px) {
              flex-direction: unset;
              justify-content: center;
              align-items: center;
            }

            img {
              width: 120px;
              height: 120px;
              object-fit: cover;
              border: 2px solid #ccc;
              cursor: pointer;
              transition: border 0.3s;

              @media (max-width:500px) {
                flex-direction: column;
                width: 80px;
                height: 80px;
              }

              &.active {
                border-color: #000;
              }

              &:hover {
                border-color: #333;
              }
            }
          }

          &__info {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: space-between;
            gap: 20px;

            .info-down {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: start;
              gap: 10px;
              @media(max-width: 450px) {
                align-items: center;
              }
            }

            @media (min-width:900px) {
              height: 394px;
            }

            @media(max-width: 450px) {
              width: 100%;
              align-items: center;
            }

            &_title {
              display: flex;
              flex-direction: column;
            }

            span {
              font-family: Inter;
              font-size: 20px;
              font-weight: 400;
              line-height: 24.2px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              text-wrap: wrap;
            }

            p {
              margin: 5px 0;
            }

            &_quantity {
              display: flex;
              align-items: center;
              gap: 10px;

              @media(min-width:900px) {
                width: 100%;
                justify-content: space-between;
              }

              button {
                width: 30px;
                height: 30px;
                font-size: 1rem;
                border: none;
                background-color: none;
                background: none;
                cursor: pointer;
              }
            }

            &_add-to-cart {
              cursor: pointer;
              margin-top: 30px;
            }
          }

          &__info_quantity {
            border: 1px solid rgb(183, 183, 183);
            padding: 6px;
            border-radius: 20px;

            span {
              width: 20px;
              text-align: center;
            }

            button {
              border: 1px solid rgb(111, 111, 111);
              border-radius: 50%;
              transition: all .3s;

              &:hover {
                background-color: #f7f7f7;
              }
            }
          }

          &__add-chart {
            width: 100%;
            border: 1px solid rgb(183, 183, 183);
            border-radius: 20px;
            padding: 6px 6px 4px 6px;
            cursor: pointer;
            transition: all .3s;

            &__activ {
              width: 100%;
              border: 1px solid rgb(183, 183, 183);
              border-radius: 20px;
              padding: 6px 6px 4px 6px;
              cursor: pointer;
              transition: all .3s;

              @media(max-width:450px) {
                background-color: #535353;
              }

              p {
                color: white;
              }

              img {
                margin: 0;
              }
            }

            @media(min-width:450px) {
              &:hover {
                background-color: #f7f7f7;
              }
            }

            img {
              margin: 0;
            }
          }

          &__info_title {
            max-width: 150px;

            @media(max-width:900px) {
              max-width: 330px;
            }
          }
        }
      }

      &_info {
        overflow: hidden;

        p {
          font-family: Inter;
          font-size: 22px;
          font-weight: 200;
          line-height: 26.63px;
          letter-spacing: 0.15em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          max-width: 500px;
          line-height: 35px;
        }
      }
    }

    &_recommend {
      display: flex;
      gap: 50px;
      flex-wrap: wrap;

      @media(max-width:450px) {
        align-items: center;
        justify-content: center;
      }

      &__box {
        display: flex;
        flex-direction: column;

        &__title {
          margin-top: 10px;
          max-width: 150px;

          span {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}