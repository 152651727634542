.faq_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .faq {
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    gap: 85px;
    @media(max-width:450px) {
      padding: 40px 60px 60px 60px;
      gap: 40px;
    }
    &_title {
      p {
        font-size: 24px;
        font-weight: 400;
        line-height: 29.05px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }

    &_main {
      display: flex;
      justify-content: space-between;
      align-items: start;
      gap: 50px;

      @media (max-width:700px) {
        flex-direction: column;
      }

      &_left {
        display: flex;
        flex-direction: column;
        gap: 30px;
        text-align: left;
        max-width: 634px;

        span {
          font-size: 20px;
          font-weight: 400;
          line-height: 29.05px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }

        p {
          font-size: 20px;
          font-weight: 300;
          line-height: 24.2px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

        }

        a {
          font-size: 20px;
          font-weight: 400;
          line-height: 24.2px;
          text-align: left;
          text-decoration-line: underline;
          text-decoration-style: solid;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: rgb(0, 0, 0);
        }
      }

      &_right {
        display: flex;
        flex-direction: column;
        gap: 30px;
        text-align: left;
        max-width: 634px;

        span {
          font-size: 20px;
          font-weight: 400;
          line-height: 29.05px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

        }

        p {
          font-size: 20px;
          font-weight: 300;
          line-height: 24.2px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

        }

        a {
          font-size: 20px;
          font-weight: 400;
          line-height: 24.2px;
          text-align: left;
          text-decoration-line: underline;
          text-decoration-style: solid;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: rgb(114, 114, 114);
          text-decoration: unset;

        }
      }


    }

    &_info {
      width: 100%;
      display: flex;
      justify-content: left;

      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 24.2px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      a {
        color: black;
      }
    }
  }
}