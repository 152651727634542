.admin {
  display: flex;
  padding: 150px 60px;
  gap: 50px;

  @media(max-height:800px) {
    padding: 80px;
  }

  @media(max-width:850px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }

  &_nav {
    ul {
      text-decoration: none;
      list-style: none;
      display: flex;
      flex-direction: column;

      @media(max-width:850px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
      }

      .activ {
        color: rgb(0, 0, 0);
      }

      li {
        margin-bottom: 5px;
        font-family: Inter;
        font-size: 24px;
        font-weight: 400;
        line-height: 48.09px;
        letter-spacing: 0.15em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: rgb(96, 96, 96);
        cursor: pointer;

        &:hover {
          color: rgb(0, 0, 0);

        }
      }
    }
  }
}