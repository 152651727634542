.brands {
  display: flex;
  gap: 50px;
  flex-wrap: wrap;

  &_item {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 10px;
    position: relative;
    p {
      font-family: "Chivo", sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.28px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
    &_box{
      div{
        position: absolute;
        top: 10px;
        right: 10px;
        img{
          cursor: pointer;
        }
      }
    }
  }
}