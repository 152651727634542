.payment {
  width: 100%;
  height: calc(100vh - 54px);
  display: flex;
  justify-content: center;
  align-items: center;

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    @media(max-width:450px) {
      padding: 20px;

    }

    span {
      font-family: Inter;
      font-size: 30px;
      font-weight: 300;
      line-height: 36.31px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      @media(max-width:550px) {
        font-size: 20px;
      }

      @media(max-width:450px) {
        font-size: 18px;
      }
    }

    p {
      width: 300px;
      font-family: Inter;
      font-size: 18px;
      font-weight: 300;
      line-height: 21.78px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      @media(max-width:550px) {
        width: 200px;
      }

      @media(max-width:450px) {
        font-size: 16px;
        width: 150px;
      }
    }

    button {
      border: none;
      background-color: black;
      color: white;
      font-size: 16px;
      line-height: 22.5px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      border-radius:24px ;
      padding: 8px 24px;
      cursor: pointer;
    }
  }
}