$primary-color: #007bff;
$text-color: #333;
$border-radius: 8px;
$background-color: #f9f9f9;

.delivery_address {
  width: 100%;
  height: 100%;

  form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 150px;
    gap: 20px;
    @media (max-width:1200px) {
      gap: 20px;
    }

    .addres_input {
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: start;
      position: relative;

      small {
        color: red;
        font-size: 12px;
        position: absolute;
        bottom: -17px;
      }
    }

    div {
      display: flex;
      gap: 12px;

      input {
        width: 100%;
        padding: 12px 6px;
        background-color: #F8F9FA;
        border: none;
        box-shadow: 0px 4px 4px 0px #00000040 inset;

      }

      select {
        width: 100%;
        padding: 10px 3px;
        font-size: 14px;
        border: 1px solid lighten($text-color, 50%);
        background-color: $background-color;
        cursor: pointer;
        border: none;
        box-shadow: 0px 4px 4px 0px #00000040 inset;
        color: #333;

        // appearance: none; 
        // -webkit-appearance: none; 
        // -moz-appearance: none; 
        &:focus {
          outline: none;
        }
      }
    }
  }
}