.account-form {
  section {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .form-group1 {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      >div {
        display: flex;
        gap: 40px;

        div {
          display: flex;
          flex-direction: column;
          text-align: left;
          gap: 5px;
        }
      }

      h3 {
        margin-bottom: 10px;
        font-family: "Chivo", sans-serif;
      }

      input[type="text"],
      input[type="number"],
      input[type="email"] {
        font-family: "Chivo", sans-serif;
        width: 200px;
        height: 40px;
        padding-left: 10px;
        box-shadow: 0px 4px 4px 0px #00000040 inset;
        border: none;
        outline: none;

        @media (max-width:600px) {
          width: 155px;
        }
      }
    }

    .form-group2 {
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      >div {
        display: flex;
        gap: 40px;

      }

      h3 {
        font-family: "Chivo", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.04px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      input[type="text"],
      input[type="number"],
      input[type="email"] {
        font-family: "Chivo", sans-serif;
        height: 40px;
        padding-left: 10px;
        box-shadow: 0px 4px 4px 0px #00000040 inset;
        border: none;
        outline: none;
        width: 440px;
        @media (max-width:600px) {
          width: 350px;
        }
      }
    }

    .form-group3 {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-bottom: 20px;
    }

    .gender input[type="radio"] {
      display: none;
      font-family: "Chivo", sans-serif;
    }

    .gender label {
      font-family: "Chivo", sans-serif;
      display: inline-block;
      padding: 5px 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 10px;
      background-color: #f9f9f9;
      transition: background-color 0.3s, border-color 0.3s;
    }

    .gender input[type="radio"]:checked+label {
      background-color: #000000;
      border-color: #000000;
      color: white;
    }

    .gender label:hover {
      background-color: #e0e0e0;
    }

  }

  .account-form_save {
    button {
      font-family: "Chivo", sans-serif;
      background-color: black;
      color: white;
      border-radius: 4px;
      padding: 5px 20px;
      margin: 20px 0;
      cursor: pointer;
      border: none;

      &:hover {
        background-color: rgba(0, 0, 0, 0.751);

      }
    }
  }

  small {
    font-family: "Chivo", sans-serif;
    color: red;
  }
}