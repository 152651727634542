.product {
  display: flex;
  padding: 40px 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 450px) {
    padding: 40px 20px 60px 20px;
  }

  &_container {
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    &_list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 20px; 
      margin-top: 50px;
      justify-content: center;

      @media (max-width: 640px) {
        grid-template-columns: repeat(2, 1fr); 
      }

      &_item {
        display: flex;
        flex-direction: column;
        position: relative;
        background: #f9f9f9;
        border: 1px solid #e0e0e0;
        border-radius: 12px;
        overflow: hidden;
        transition: box-shadow 0.3s ease;
        width: 100%;
        max-width: 160px;
        cursor: pointer; 

        &:hover {
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        }

        > img {
          width: 100%;
          max-width: 160px;
          height: 198px;
          object-fit: fill;
          object-position: center;
          transition: transform 0.3s ease, z-index 0.3s ease;

          &:hover {
            transform: scale(1.1);
            z-index: 2;
          }
        }

        &_info {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding: 10px;
          background: #fff;
          border-top: 1px solid #e0e0e0;
          justify-content: space-between;
          height: 95px;

          &_title {
            display: flex;
            justify-content: space-between;
            align-items: start;
            gap: 10px;

            img {
              width: 20px;
              height: 20px;
              cursor: pointer;
              transition: transform 0.2s ease;

              &:hover {
                transform: scale(1.2);
              }
            }

            p {
              font-family: 'Inter', sans-serif;
              font-size: 18px;
              font-weight: 500;
              line-height: 22.5px;
              color: #333;
              cursor: pointer;
              transition: color 0.3s ease;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              max-height: 70px;
              text-align: start;
            }
          }

          &_desc {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
              font-size: 16px;
              font-weight: bold;
            }

            img {
              width: 24px;
              height: 24px;
              cursor: pointer;
              transition: transform 0.2s ease;

              &:hover {
                transform: scale(1.1);
              }
            }
          }
        }
      }
    }
  }
}
