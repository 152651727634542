.price_modal_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;
  &_title {
    p {
      font-family: Inter;
      font-size: 24px;
      font-weight: 400;
      line-height: 29.05px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

    }
  }

  &_city {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: left;
    &_box{
      width:250px ;
      &_item{
        margin-bottom: 1px;
        display: flex;
        justify-content: space-between;
        p{
          font-size: 14px;
        }
      }
    }
  }
  &_close{
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
}