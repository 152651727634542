.corporate {
  width: 100%;
  padding: 40px 60px 60px 60px;

  &_section {
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;

    @media (max-width:500px){
      gap: 30px;

    }
    &_title {
      p {
        font-family: Inter;
        font-size: 25px;
        font-weight: 300;
        line-height: 30.26px;
        letter-spacing: 0.15em;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        @media (max-width:500px){
          font-size: 20px;
        }
      }
    }

    &_container {
      display: flex;
      gap: 100px;

      @media (max-width:1500px) {
        gap: 50px
      }

      @media (max-width:1250px) {
        width: 100%;
        justify-content: space-between;
      }

      &_img {
        img {
          width: 100px;

          @media (max-width:1700px) {
            width: 80px;
          }

          @media (max-width:1250px) {
            width: 50px;
          }
          @media (max-width:600px){
            display: none;
          }
        }
      }

      &_corporateImg {
        @media (max-width:1250px) {
          display: none;
        }
      }

      &_main {
        display: flex;
        flex-direction: column;
        gap: 80px;

        @media (max-width:1500px) {
          gap: 50px
        }
        
        @media (max-width:500px){
          gap: 20px;

        }

        &_title {
          p {
            width: 660px;
            font-family: Inter;
            font-size: 25px;
            font-weight: 200;
            line-height: 30.26px;
            letter-spacing: 0.15em;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;

            @media (max-width:1700px) {
              width: 500px;
              font-size: 22px;

            }

            @media (max-width:1500px) {
              width: 400px;
              font-size: 18px;
            }
            @media (max-width:800px){
              width: 100%;
            }

            @media (max-width:500px){
              font-size: 14px;
              line-height: 15.26px;

            }
          }
        }

        &_form {
          display: flex;

          form {
            width: 450px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: start;
            @media (max-width:800px){
              width: 100%;
            }
            div {
              display: flex;
              gap: 10px;
              width: 100%;

              input {
                width: 100%;
                padding: 12px 6px;
                background-color: #F8F9FA;
                border: none;
                box-shadow: 0px 4px 4px 0px #00000040 inset;
              }

              textarea {
                width: 100%;
                height: 150px;
                resize: none;
                border: none;
                background-color: #F8F9FA;
                box-shadow: 0px 4px 4px 0px #00000040 inset;
                padding: 15px;
                font-family: Chivo;
                font-size: 15px;
                font-weight: 200;
                line-height: 17.85px;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
              }

            }

            .corporate_button {
              width: 100%;
              margin-top: 10px;

              button {
                color: white;
                background-color: black;
                padding: 10px;
                width: 200px;
                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
                line-height: 19.36px;
                text-align: center;
                text-underline-position: from-font;
                box-shadow: 0px 4px 4px 0px #00000040;
                text-decoration-skip-ink: none;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}